import React from 'react'
import './Quote.scss'
const Quote = () => {
  return (
    <div>
        <div className='Qcontainer'>
     <h1 className='Qheading'>Revolutionizing</h1>
     <div className='QimageContainer'>
     <img src="./h1.svg" width="170px" height="200px" alt="" />
     </div>
     <div className='quoteSection'>
        <div className="firstComma">
       <img src="q2.svg" alt="" />
       </div>

       <div className="Qtext">
      <p className='Qparagraph'>
      Empowering individuals with cutting-edge technology and compassionate care, we're pioneering a healthcare revolution. Our commitment to innovation and personalized wellness journeys is transforming the way health is perceived and achieved. Join us on this transformative path towards a healthier, happier future.
    </p>
       </div>

       <div className="lastComma">
       <img src="qr1.svg" alt="" />
       </div>
     </div>
    </div>
    </div>
  )
}

export default Quote
