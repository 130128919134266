import React from 'react'
import "./Brain.scss"
const Brain = () => {
    
    const Chestcontainer = {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "5rem",
        marginLeft: "7rem",
        marginTop: "5rem",
      };
    
      const chestleft = {
        float: "left",
        display: "flex",
        left: "0px",
        flexDirection: "column",
       
        justifyContent: "center",
        flex: "2",
        textAlign:"justify"
      };
    
      const chestright = {
        flex: "1",
        display: "flex",
        marginLeft: "8rem",
        marginRight:"2rem",
        flexDirection: "column",
        paddingLeft: "50px",
        justifyContent: "center",
        alignItems: "center",
      };
    
  return (
    <div>
      <div className='ChestWrapper'>
      
      <div className="HeaderImage">
          <img src="img/coverbrain.svg"   alt="" />
      </div>
     

      <div className="body">
      {/* <div className="featureHeading">
        <h1 >
          Our <span className="primary"> Features </span>
        </h1>
      </div> */}
      {/* <div className="decor">
        <img
          src="img/BackgroundDna.svg"
          height="400px"
          width="400px"
          alt=""
        />
      </div> */}

      <div className="featureContainer" id="contact-us">
        <div style={Chestcontainer}>
       
          <div style={chestleft}>
              <div className="ChestHeading">
                  <h2>AI for <span className='chestPrimary'>Brain X-Ray</span></h2>
              </div>
            <p className='ChestPara'>
            
            Step into the future of healthcare workflow! Our AI isn’t just a tool; it's your tireless assistant, automating routine tasks like interpreting brain scans. This isn’t about replacing doctors; it’s about freeing them from the mundane, allowing them to focus on the art of healing. The result? A streamlined process that unleashes the full potential of healthcare professionals.Our AI doesn’t just stop at diagnostics; it's a game-changer for the entire patient experience. By taking the routine off doctors' plates, they can dedicate more time to personalized treatment plans, compassionate patient communication, and holistic care coordination. It's not just about detecting diseases; it's about transforming healthcare into a patient-centric symphony.</p>
           
           <br/>
           <p className='ChestPara'>
           Picture a world where advanced diagnostics aren’t confined to metropolitan hubs. Our AI technology is a beacon of hope for remote and underserved areas. No longer limited by geographical constraints, AI-assisted neuroimaging brings specialized medical expertise to every corner of the globe, bridging gaps and fostering healthcare equality.Welcome to the future of brain disease detection, where AI isn’t just a tool; it’s a visionary force propelling us towards a new era of healthcare. Embrace the possibilities, champion the extraordinary, and be part of a journey that transforms the way we understand and combat brain diseases. 
            </p>
          </div>
          <div style={chestright}>
          <div className="ContactUsImage">
              <img
                src="img/sideB.svg"
                width="400px"
                height="500px"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

       
    </div>




  </div>
    </div>
  )
}

export default Brain
