import React from 'react'
import './Header.scss'
import  { useState } from 'react';




import { Link as RouterLink } from 'react-router-dom';


const Header = () => {
  const scrollToContact = () => {
    const contactElement = document.getElementById("contact");
  
    if (contactElement) {
      contactElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };



  const [isActive, setIsActive] = useState(false);

  const toggleDropdown = () => {
    setIsActive(!isActive);
  };
  return (
    <div className='header-background'>
    <div className='Hcontainer'>
      <div className="left">
       <div className="Himage"><img src="img/logo.jpg" alt="" /></div>

      </div>
      <div className="right">
<div className="item">

<RouterLink className="link" to="/">Home</RouterLink>
</div>

<div className={`item ${isActive ? 'active' : ''}`} onClick={toggleDropdown}>
      <span className="link">Services</span>
      {/* Dropdown menu */}
      {isActive && (
        <div className="dropdown">
           <div className="dropdown-box">
          <RouterLink className='routerLinks'  to="/chest">AI for Chest X-Ray</RouterLink>
          <RouterLink  className='routerLinks' to="/lungs">AI for Lungs CT</RouterLink>
          <RouterLink  className='routerLinks'to="/brain">AI for Brain CT</RouterLink>
          <RouterLink  className='routerLinks' to="/mammograms">AI for Mammograms</RouterLink>
       
         
          {/* Add more service links as needed */}
        </div>
        </div>
      )}
    </div>

{/* <div className="item">

<RouterLink className="link" to="/">News</RouterLink>
</div> */}


<div className="item">

<RouterLink className="link" to="/Aboutus">About Us</RouterLink>
</div>


<div className="item">




<RouterLink className="link" to="/" onClick={scrollToContact}>
  Contact Us
</RouterLink>
{/* <ScrollLink to="contact" smooth={true} duration={500}   className="link" offset={-50}>Contact US</ScrollLink> */}

</div>
      </div>
    
    </div>
    </div>

   
  )
}

export default Header
