import React from 'react'
import './Chest.scss'
import './Chest.scss'

const Chest = () => {
    

    const Chestcontainer = {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "5rem",
      marginLeft: "7rem",
      marginTop: "5rem",
    };
  
    const chestleft = {
      float: "left",
      display: "flex",
      left: "0px",
      flexDirection: "column",
     
      justifyContent: "center",
      flex: "2",
      textAlign:"justify"
    };
  
    const chestright = {
      flex: "1",
      display: "flex",
      marginLeft: "8rem",
      marginRight:"2rem",
      flexDirection: "column",
      paddingLeft: "50px",
      justifyContent: "center",
      alignItems: "center",
    };
  
  return (
    <div className='ChestWrapper'>
      
        <div className="HeaderImage">
            <img src="img/coverPhotoL2.svg"   alt="" />
        </div>
       

        <div className="body">
        {/* <div className="featureHeading">
          <h1 >
            Our <span className="primary"> Features </span>
          </h1>
        </div> */}
        {/* <div className="decor">
          <img
            src="img/BackgroundDna.svg"
            height="400px"
            width="400px"
            alt=""
          />
        </div> */}

        <div className="featureContainer" id="contact-us">
          <div style={Chestcontainer}>
         
            <div style={chestleft}>
                <div className="ChestHeading">
                    <h2>AI for <span className='chestPrimary'>Chest X-Ray</span></h2>
                </div>
              <p className='ChestPara'>Artificial Intelligence (AI) is poised to revolutionize the detection of brain tumors and other abnormalities in MRI scans, ushering in a new era of precision and efficiency in healthcare diagnostics.One key advantage of AI in brain MRI analysis is its ability to quickly process vast amounts of imaging data with a high level of consistency. The speed at which AI algorithms can analyze large datasets makes it possible to obtain results in a fraction of the time it would take through conventional methods, thereby expediting patient care and reducing the time between detection and intervention.The integration of AI into brain MRI analysis not only enhances accuracy and efficiency but also holds the promise of improving accessibility to healthcare services. In regions where expert radiologists are scarce, AI can serve as a force multiplier, extending diagnostic capabilities to areas with limited medical resources.</p>
            </div>
            <div style={chestright}>
            <div className="ContactUsImage">
                <img
                  src="img/sideC2.svg"
                  width="400px"
                  height="500px"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

         
      </div>




    </div>
  )
};

export default Chest
