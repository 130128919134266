import React from 'react'
import "./Lungs.scss"
const Lungs = () => {
    
    const Chestcontainer = {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "5rem",
        marginLeft: "7rem",
        marginTop: "5rem",
      };
    
      const chestleft = {
        float: "left",
        display: "flex",
        left: "0px",
        flexDirection: "column",
       
        justifyContent: "center",
        flex: "2",
        textAlign:"justify"
      };
    
      const chestright = {
        flex: "1",
        display: "flex",
        marginLeft: "8rem",
        marginRight:"2rem",
        flexDirection: "column",
        paddingLeft: "50px",
        justifyContent: "center",
        alignItems: "center",
      };

      
      const chestleft2 = {
        float: "left",
        display: "flex",
        left: "0px",
        flexDirection: "column",
       
        justifyContent: "center",
        flex: "1",
        textAlign:"justify"
      };
    
      const chestright2 = {
        flex: "2",
        display: "flex",
        marginLeft: "8rem",
        marginRight:"2rem",
        flexDirection: "column",
        paddingLeft: "50px",
        justifyContent: "center",
        alignItems: "center",
      };
    
  return (
    <div>
      <div className='ChestWrapper'>
      
      <div className="LHeaderImage">
          <img src="img/coverlungs.svg"   alt="" />
      </div>
     

      <div className="body">
      {/* <div className="featureHeading">
        <h1 >
          Our <span className="primary"> Features </span>
        </h1>
      </div> */}
      {/* <div className="decor">
        <img
          src="img/BackgroundDna.svg"
          height="400px"
          width="400px"
          alt=""
        />
      </div> */}

      <div className="featureContainer" id="contact-us">
        <div style={Chestcontainer}>
       
          <div style={chestleft}>
              <div className="ChestHeading">
                  <h2>AI for <span className='chestPrimary'>Lungs X-Ray</span></h2>
              </div>
            <p className='ChestPara'>One of the primary advantages of incorporating AI into the diagnosis of lung diseases lies in its ability to rapidly process and interpret vast amounts of imaging data. Deep learning models, trained on extensive datasets, can discern subtle patterns and anomalies in lung images, often with a level of precision that surpasses traditional diagnostic methods. This enables healthcare providers to expedite the identification of respiratory issues, including pneumonia, lung nodules, and other pulmonary abnormalities.</p><br/><p className='ChestPara'>

The integration of AI not only enhances the speed of diagnosis but also streamlines the workflow for healthcare professionals. By automating routine tasks such as the analysis of chest scans, AI allows doctors to allocate more time and attention to other critical aspects of patient care, such as treatment planning, consultation, and complex medical decision-making. This not only increases overall efficiency in healthcare delivery but also contributes to a more focused and balanced workload for medical professionals.

Furthermore, the deployment of AI in lung disease detection holds the potential to improve accessibility to timely and accurate diagnoses, particularly in regions where specialized medical expertise may be limited. Remote healthcare facilities can benefit significantly from AI-assisted diagnostics, bringing advanced medical capabilities to areas that may otherwise face challenges in accessing specialized healthcare services.</p>
          </div>
          <div style={chestright}>
          <div className="ContactUsImage">
              <img
                src="img/sideL2.svg"
                width="600px"
                height="600px"
                alt=""
              />
            </div>
          </div>
        </div>

        
      </div>

       
      
    </div>




  </div>
    </div>
  )
}

export default Lungs
