import React from 'react'
import './Footer.scss'
// In your main SCSS file
import '@fortawesome/fontawesome-free/css/all.css';

const Footer = () => {
  return (
    <div>
      <div className="FooterContainer">
        <div className="flex">
            <div className="column1">
                <div className="coulmnContent">
<h1>Address</h1>
<p>Pwd socity rawalpindi</p>

</div>

            </div>

            <div className="column2">
            <div className="coulmnContent">
<h1>Email</h1>
<p>Radiologics@gmail.com</p>

</div>
                </div>

                <div className="column3">
                <div className="columnContent">
              <h1>Socials</h1>
              <div className="social-icons">
                <a href="https://www.facebook.com/SloshAIsolutions/" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-facebook"></i> 
                </a>
                <a href="https://www.instagram.com/slosh.solutions/" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-instagram"></i> 
                </a>
                <a href="https://www.linkedin.com/company/slosh-ai-solutions/" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-linkedin"></i> 
                </a>
              </div>
                </div>
                </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
