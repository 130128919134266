import React from 'react'
import './Mammograms.scss'
const Mammograms = () => {

    
    const Chestcontainer = {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "5rem",
        marginLeft: "7rem",
        marginTop: "5rem",
      };
    
      const chestleft = {
        float: "left",
        display: "flex",
        left: "0px",
        flexDirection: "column",
       
        justifyContent: "center",
        flex: "2",
        textAlign:"justify"
      };
    
      const chestright = {
        flex: "1",
        display: "flex",
        marginLeft: "8rem",
        marginRight:"2rem",
        flexDirection: "column",
        paddingLeft: "50px",
        justifyContent: "center",
        alignItems: "center",
      };
  return (
    <div>
     <div className='ChestWrapper'>
      
      <div className="MHeaderImage">
          <img src="img/coverMammogram.svg"   alt="" />
      </div>
     

      <div className="body">
      {/* <div className="featureHeading">
        <h1 >
          Our <span className="primary"> Features </span>
        </h1>
      </div> */}
      {/* <div className="decor">
        <img
          src="img/BackgroundDna.svg"
          height="400px"
          width="400px"
          alt=""
        />
      </div> */}

      <div className="featureContainer" id="contact-us">
        <div style={Chestcontainer}>
       
          <div style={chestleft}>
              <div className="ChestHeading">
                  <h2>AI for <span className='chestPrimary'>Mammograms X-Ray</span></h2>
              </div>
            <p className='ChestPara'> 
1 out of 8 women globally has a chance of developing breast cancer at some point in life.. It is a stat that raises eyebrows, but the real story unfolds not in numbers, but in lives. Imagine the symphony of daily routines silenced, the vibrant threads of dreams unraveled, the laughter replaced by whispers of fear. Breast cancer steals, not just health, but moments, experiences, futures. It disrupts lives, strains families, and casts a shadow that stretches far beyond the body. It's a battle many fight, a battle you can help win. Early detection doesn't just offer hope, it offers a fighting chance, a shield against the disruption and darkness. Choose awareness, choose action, choose to write a different story. Let's rewrite the statistics, one mammogram at a time. </p>
          </div>
          <div style={chestright}>
          <div className="mammogramSideContainer">
              <img
                src="img/sideMammogram.svg"
                width="500px"
                height="600px"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

       
    </div>




  </div>   
    </div>
  )
}

export default Mammograms
