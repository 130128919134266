import React from "react"; // Import useState from 'react'

import "./Features.scss";

const Features = () => {
  const FContainer = {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "5rem",
    marginLeft: "7rem",
    marginTop: "5rem",
  };

  const Featuresleft = {
    float: "left",
    display: "flex",
    left: "0px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: "1",
   
  };

  const Featuresright = {
    flex: "2",
    display: "flex",
    marginLeft: "8rem",
    flexDirection: "column",
    paddingLeft: "50px",
    justifyContent: "center",
    alignItems: "center",
    textAlign:"justify",
  };

  return (
    <>
      <div className="body">
        {/* <div className="featureHeading">
          <h1 >
            Our <span className="primary"> Features </span>
          </h1>
        </div> */}
        {/* <div className="decor">
          <img
            src="img/BackgroundDna.svg"
            height="400px"
            width="400px"
            alt=""
          />
        </div> */}

        <div className="featureContainer" id="contact-us">
          <div style={FContainer}>
          <div className="fTopDecor">
          <img
            src="img/ftopDecor.svg"
            height="130px"
            width="130px"
            alt=""
          />
        </div> 
            <div style={Featuresleft}>
              <div className="ContactUsImage">
                <img
                  src="img/femaleDoc.svg"
                  width="400px"
                  height="500px"
                  alt=""
                />
              </div>
            </div>
            <div style={Featuresright}>
              <div class="Feature-headingsContainer">
                <h2 className="Feature-blue">
                  Trust your health to{" "}
                  <span className="Feature-yellow">
                    the the Best Specialist
                  </span>
                </h2>
              </div>

              <form>
                <div class="GridContainer">
                  <div class="FeatureColumn1">
                    {" "}
                    <img src="img/time.png" height="50px" width="50px" alt="" />
                  </div>
                  <div class="FeatureColumn2">
                    <div class="item">
                      {" "}
                      <p className="feature">Reduced Reporting time </p>
                    </div>
                    <div class="item">
                      {" "}
                      <p className="feature2">
                        {" "}
                        Harness the power of AI to streamline reporting processes, saving time and accelerating decision-making.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="GridContainer">
                  <div class="FeatureColumn1">
                    {" "}
                    <img
                      src="img/profile.png"
                      height="50px"
                      width="50px"
                      alt=""
                    />
                  </div>
                  <div class="FeatureColumn2">
                    <div class="item">
                      {" "}
                      <p className="feature">Rapid triage </p>
                    </div>
                    <div class="item">
                      {" "}
                      <p className="feature2">
                        {" "}
                        Our AI-driven rapid triage solutions redefine efficiency in incident management. {" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="GridContainer">
                  <div class="FeatureColumn1">
                    {" "}
                    <img
                      src="img/puzzle.png"
                      height="50px"
                      width="50px"
                      alt=""
                    />
                  </div>
                  <div class="FeatureColumn2">
                    <div class="item">
                      {" "}
                      <p className="feature">Easy integration into healthcare systems </p>
                    </div>
                    <div class="item">
                      {" "}
                      <p className="feature2">
                        {" "}
                        Experience effortless integration of our AI solutions into your healthcare systems. <br/>     Our technology is designed to seamlessly align with your existing infrastructure, ensuring a smooth <br/>  and efficient incorporation into your workflows.{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="GridContainer">
                  <div class="FeatureColumn1">
                    {" "}
                    <img
                      src="img/money.png"
                      height="50px"
                      width="50px"
                      alt=""
                    />
                  </div>
                  <div class="FeatureColumn2">
                    <div class="item">
                      {" "}
                      <p className="feature">Cost effective</p>
                    </div>
                    <div class="item">
                      {" "}
                      <p className="feature2">
                        {" "}
                         Empower your operations with our cost-effective AI solutions. We prioritize efficiency without<br/> compromising quality, delivering cutting-edge technology that enhances your processes <br/>  while being mindful of your budgetary considerations. Unlock advanced capabilities<br/>  without breaking the bank.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

         <div className="dnaBottom">
          <img
            src="img/fdecor.svg"
            height="150px"
            width="150px"
            alt=""
          />
        </div> 
      </div>
    </>
  );
};

export default Features;
