import React, { useState } from 'react'
import './Slider.scss'
import {FaArrowAltCircleRight,FaArrowAltCircleLeft} from 'react-icons/fa'
const Slider = () => {
  const scrollToContact = () => {
    const contactElement = document.getElementById("contact");
  
    if (contactElement) {
      contactElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

   return (

  <div className="HeroSliderContainer">

    <div className="img">
      <img src="img/circle.svg" alt=""  width="400px" height="400px"  className="blueCircle" />
    </div>
<div className="HeroSliderLeft">
  <div className="SlidertextConatiner">
<div class="HeroHeadings-container">
 
<h2 class="best-specialist">Revolutionize Healthcare with  <br/><span className="yellow-text">Our Latest Technologies</span></h2>

</div>


<div className="HeroSliderpara">
<p>Welcome to a new era of healthcare transformation, where our AI tools redefine the very fabric of medical practice. Seamlessly blending innovation and precision, our revolutionary technologies empower doctors with unprecedented capabilities. From swift diagnostics to personalized treatment plans, our AI tools are the architects of efficiency</p>
</div>

<div >
<button class="heroButton" onClick={scrollToContact} style={{ fontWeight: 'bold'}}>Contact US</button>
</div>
</div>
</div>
<div className="HeroSliderRight">

<img src="img/doctor1.svg" alt="" className="doctor1"  width="600px"  height="600px" />
  
    <img src="img/pattern.svg" alt="" className="pattern"  width="700px"  height="700px" />
  
</div>

<img src="img/circle2.svg" alt=""   className="blueCircle2" />


  </div>

   )
};
export default Slider














//     const slides = [
        
//         'https://images.pexels.com/photos/7853221/pexels-photo-7853221.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
//         'https://images.pexels.com/photos/243759/pexels-photo-243759.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
//         'https://images.unsplash.com/photo-1704587344247-22470040fec8?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        
      
//       ];
//     const [currentSlide,setCurrentSlide]=useState(0)
//     const next = () =>{


//     setCurrentSlide(currentSlide === slides.length-1 ? 0:currentSlide+1 )
//     }

//     const prev = () =>{
//         setCurrentSlide(currentSlide === 0?slides.length-1 : currentSlide-1)
//     }

//     console.log(currentSlide)
//   return (
//     <div>
//     <div className='slider'>
//       <FaArrowAltCircleLeft className='left-arrow' onClick={prev}/>
//       <FaArrowAltCircleRight className='right-arrow' onClick={next}/>

//       {slides.map((slide,index) => (
//         <div className={index === currentSlide?'slide active': 'slide' } key={index} >
//   {index === currentSlide && (
//  <img
//  key={index}
//  src={slide}
//  className='image'
//  alt={`Slide ${index + 1}`}

// />

//   )}
 
// </div>
//       ))}
//     </div>
//     </div>
//   );
// };