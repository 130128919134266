

import React, { useState } from 'react';  // Import useState from 'react'
import axios from 'axios';


import './Contact.scss'

const ContactUS = () => {

  const container = {
    display: 'flex',
    justifyContent: 'center',
    marginBottom:'1rem',
    position:'relative',
   
  };
 
  const cleft = {
    flex:'1',
    display:'flex',
    
    flexDirection:'column',
    alignItems: 'center',
    justifyContent:'center',
   
  };
 
  const cright = {
    flex:'1',
    display:'flex',
    
    flexDirection:'column',
    paddingLeft: '30px',
    textAlign:'justify',
    justifyContent:'center',
  };
 



  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Save data to the database
      const databaseResponse = await axios.post('http://127.0.0.1:8000/message/', formData);

      // Send email
      const emailResponse = await axios.post('http://127.0.0.1:8000/send_email/', new URLSearchParams(formData), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      // Handle the responses from the Django server if needed
      console.log('Response from Django (Database):', databaseResponse.data);
      console.log('Response from Django (Email):', emailResponse.data);

      // Clear the form after successful submission
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: '',
      });
    } catch (error) {
      // Handle any errors that occur during the requests
      console.error('Error:', error);
    }
  };

  // const handleEmail = async (e) => {
  //   e.preventDefault();
  
  //   try {
  //     const response = await axios.post('http://127.0.0.1:8000/send_email/', new URLSearchParams(formData), {
  //       headers: {
  //         'Content-Type': 'application/x-www-form-urlencoded',
  //       },
  //     });
  
  //     console.log('Response from Django:', response.data);
  
  //     setFormData({
  //       name: '',
  //       email: '',
  //       phone: '',
  //       message: '',
  //     });
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };
  
  

  return (
    <>
    <div  className='contactContainer' id="contact">
    
      <div className="cheading">
    <h1 className="flexCenter">Contact   <span className="primaryText">   Us  </span></h1>
   
    </div>
    {/* <h1 className=" cheading flexCenter">Let's Get In <span className="primaryText"> Touch </span></h1> */}
    <div style={container}>
    <div className="img">
      <img src="img/circle.svg" alt=""  width="400px" height="400px"  className="blueCircle" />
    </div>
      <div style={cleft}>
     
      <div className="ContactUsImage">
              <img src="img/handContact3.svg"  width="600px"  height="600px" alt="" />
            </div>

      </div>
      <div style={cright}>
      <form onSubmit={handleSubmit} >
  <div>
  <label for="Name" className="label">Full Name</label>
    <div  className="feildContainer" >
    
    <input   type="text" className="inputForm" id="name"  pattern="[A-Z a-z]*" title='Enter Valid Name'   name="name"
                    value={formData.name}
                    onChange={handleChange} required/>
    </div>

    <label for="exampleInputEmail1" className="label" >Email Address</label>
    <div className="feildContainer"  >
  
    <input type="email" className="inputForm" id="email" aria-describedby="emailHelp"  name="email"
                    value={formData.email}
                    onChange={handleChange}  required/>
    </div>

    

    <label for="exampleInputEmail1" className="label">Message</label>
    <div className="feildContainer"  >
   
    <textarea className="inputForm"  id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}   aria-describedby="emailHelp"/>
    </div>


   
  </div>
  
 
  <button type="submit" class="btn buttonCol btn-primary ">Submit</button>
  
</form>
      </div>
    </div>
    <div className="bottomdecor">
      <img src="img/Cdecor.svg"  alt="" />
    </div>
    </div>
</>



    
  )
}

export default ContactUS
