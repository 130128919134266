import logo from './logo.svg';
import './App.css';
import Header from './components/Header/Header';

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
} from "react-router-dom";
import Slider from './components/Slider/Slider';
import Features from './components/Features/Features';
import ContactUS from './components/Contact/Contact';
import Partner from './components/Partner/Partner';
import Achievements from './components/Achievements/Achievements';
import Footer from './components/Footer/Footer';
import Quote from './components/Quote/Quote';
import Chest from './components/Chest/Chest';
import Mammograms from './components/Mammograms/Mammograms';
import Lungs from './components/Lungs/Lungs';
import Brain from './components/Brain/Brain';

// function App() {
//   return (
//     <div >
//       <Router>
//  <Header/>
//  </Router>
//  <Slider/>
//  <Features/>

//  {/* <Achievements/> */}
//  <ContactUS/>
//  <Partner/>
//  <Footer/>


//     </div>
//   );
// }

// export default App;




const Layout=()=>{
  return(
    <div className="app">
    
    <Header/>
    <Outlet/>
    <Footer/>
    </div>
  )
}

// const About = ({ children }) => {
//   return (
//     <div className="app">
//       <Header />
     
//       <Quote/>
//       <Achievements/>
//       {children}
//       <Footer />
//     </div>
//   );
// };

const Home = () => (
  <>
    <Slider />
    <Features />
    <ContactUS />
  </>
);

const About = () => (
  <>
    <Quote />
    
  
  </>
);

const router =createBrowserRouter([
  {
path:'/',
element:<Layout/>,
children:[
  {
    path:"/",
   
    element:<Home />,
    
   
  },
  {
    path:"/Aboutus",
   
    element:<About />,
  
  },
  {
    path:"/chest",
   
    element:<Chest />,
  
  },
  {
    path:"/mammograms",
   
    element:<Mammograms />,
  
  },
  {
    path:"/lungs",
   
    element:<Lungs />,
  
  },
  {
    path:"/brain",
   
    element:<Brain />,
  
  },
]
},

  
])
function App() {
  return (
    <div className="App">
        <RouterProvider router={router} />
    </div>
  );
}

export default App;